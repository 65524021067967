<template>
  <div class="lt-list" >
    <div class="lt-item" @click="gotoBetroom" v-for="(item, index) in list" :key="index">
      <div class="t1">{{item.name}}</div>
      <div class="t2">{{item.issue.last_issue}}{{$t('lun')}}</div>
      <div class="t3">{{$t('dianwei')}}：{{item.total_robot||0}}</div>
    </div>
  </div>
</template>
<script>
import homeApi from "@/api/home";
export default {
  data() {
    return {
      list: [],
      timer: "",
     
    };
  },
  computed: {},
  methods: {
    gotoBetroom() {
      this.$router.push({
        path: "/lottery",
      });
    },
    addRobotNumber(){
      this.list.forEach(v => {
        let totalRobot = 0
        v.planList.forEach(vv =>{
          totalRobot+= vv.robot_num;
        })
        v.total_robot = totalRobot
        
      });

    },
    info() {
      this.$toast.loading({
        message: this.$t('jiazaizhong'),
        forbidClick: true,
        duration: 0,
      });
      homeApi
        .Lotteryinfo(1)
        .then((data) => {
          console.log(data.data);
          this.list=data.data.gameList
          this.$toast.clear();
          this.start()
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.msg);
        });
    },
    start(){
      this.addRobotNumber()
      this.timer = setInterval(this.valChange, 1000); // 注意: 第一个参数为方法名的时候不要加括号;
    },
    valChange() {
      this.value++;
      this.list.forEach(v=>{
        if(Math.random() >0.5){
          v.total_robot += Math.floor(Math.random()*v.total_robot/100);
        }else{
          v.total_robot -= Math.floor(Math.random()*v.total_robot/100);
        }
        
        // console.log(v.total_robot);
      })
      
    },
  },
  mounted() {},
  created() {
    this.info();
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="less" scoped>
.lt-lis ::-webkit-scrollbar {
  display: none;
}
.lt-list {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: stretch;
  padding: 0 0.2rem;
  box-sizing: border-box;
  white-space: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  //    padding-bottom: 20px;
}
::-webkit-scrollbar {
  display: none;
}
.lt-item {
  display: flex;
  justify-content: center;
  display: inline-block;
  flex: 1;
  min-width: 4.65rem;
  margin-right: 0.33rem;
  padding: 0.49rem 0rem;
  border-radius: 4px;
  background: #1a243f;
  text-align: center;
}
.t1 {
  color: #fff;
  font-size: 0.35rem;
}
.t2 {
  padding: 0.2rem 0.1rem;
  color: #b0b0b0;
  font-size: 0.3rem;
  word-break: break-all;
  white-space: normal;
}
.t3 {
  color: #4bb3e8;
  font-size: 0.34rem;
}
</style>